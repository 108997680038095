@import '@angular/material/theming';
@import 'styles/themes/theme';
@import 'bootstrap/scss/bootstrap';
@import '@sweetalert2/themes/material-ui/material-ui.scss';

@include mat-core();

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
table {
    width: 100%;
}
.material-icon {
    vertical-align: bottom;
}
.variant-icon {
    color: #ff9800;
}
.deviation-icon {
    color: #9c27b0;
}
td.mat-cell.icon-cell {
    text-align: right !important;
    width: 3%;
    max-width: 3%;
}
.flex-spacer {
    flex: 1 1 auto;
}
.mat-tab-body-wrapper {
    flex-grow: 1;
}
.mat-drawer-content {
    margin-bottom: 1em;
}
.mat-form-field {
    display: block !important;
}
.advanced-pie-legend .legend-items-container .legend-items {
    overflow: visible !important;
}
.advanced-pie-legend .total-value {
    margin-bottom: 1% !important;
}
.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
    margin-top: 0px !important;
}
.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-label {
    margin-top: 5px !important;
}
.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-percent {
    margin-top: 10% !important;
}
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top-color: #000;
    animation: spinner .8s linear infinite;
}
.sweetAlertTitle {
    color: #fff !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #e87130 !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgb(232 113 48 / 69%) !important;
}

::ng-deep .dark-theme {
    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
        background-color: #e87130 !important;
    }
    .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
        background-color: rgb(232 113 48 / 69%) !important;
    }
}

::ng-deep .advanced-pie-legend .legend-items-container .legend-items .legend-item {
    display: inherit !important;
}
.pin-identifier {
    color: #000;
    position: absolute;
    top: 26px;
    text-align: center !important;
    width: 105%;
    font-size: 10px;
    font-weight: 600;
}
.leaflet-div-icon {
    background: rgba(255, 255, 255, 0) !important;
    border: 1px solid #6660 !important;
}
@media(min-width: 768px){
    .col-md-20 {
        flex: 0 0 auto;
        width: 20%;
    }
}

.login-logo {
    height: 120px;
    width: 70%;
    background-image: url("/assets/images/logo/FSP_Master.png");
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: contain;
    margin: auto;
}

@media(max-width: 767px) {
    .login-logo {
        width: 95%;
    }
}

@media(min-width: 768px) and (max-width: 1024px) {
    .login-logo {
        width: 85%;
    }
}

@media(min-width: 1025px) {
    .login-logo {
        width: 70%;
    }
}

.dark-theme {
    .login-logo {
        background-image: url("/assets/images/logo/FSP_Dark.png");
    }
}

.min-login-size {
    min-width: 30%;
}

@media(max-width: 767px) {
    .min-login-size {
        width: 100%;
    }
}

@media(min-width: 768px) and (max-width: 1024px) {
    .min-login-size {
        width: 60%;
    }
}

@media(min-width: 1025px) {
    .min-login-size {
        width: 30%;
    }
}

@media (max-width: 768px) {
    .hide-on-mobile {
        display: none !important;
    }

    .mat-cell {
        font-size: 12px !important;
    }

    .mobile-width {
        width: 100% !important;
    }
}

@media (min-width: 769px) {
    .hide-on-desktop {
        display: none !important;
    }
}



.success-snackbar {
    background: #00e676;
}

.error-snackbar {
    background: #ff1744;
    color: #fff;
}

.advanced-pie-legend .legend-items-container .legend-items {
    overflow: auto;
    white-space: normal !important;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item {
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
}

tbody tr {
    cursor: pointer;
}

.mat-drawer-content {
    overflow: hidden !important;
}
